<template>
  <MyView :meta="{ viewClass: 'statistics', inFx: 'rightIn' }" ref="MyView">
    <ViewHeader icon="arrowLeft" :title="$locale.words['statistics']" @back="goBack">
      <div slot="right" class="flex-end" w100>
        <HamburgerBtn />
      </div>
    </ViewHeader>
    <ViewContent>
      <div class="statistics-content">
        <div class="statistics-main">
          <div class="statistics-header">
            <div class="statistics-header-user">
              <p class="greeting">Hola,</p>
              <p class="name">{{ $user.name }}</p>
            </div>
            <div class="statistics-header-cards">
              <StatisticsCardTotal :card="{ title: $locale.words['active-fans'], icon: 'mf_user_plus_3d', total: statistics.totalFansActive }" />
              <StatisticsCardTotal :card="{ title: $locale.words['expired-fans'], icon: 'mf_user_minus_3d', total: statistics.totalFansExpired }" />
            </div>
          </div>
          <div class="statistics-list">
            <StatisticsSubscriptions />
            <StatisticsEarnings />
            <StatisticsUsers />
          </div>
        </div>
      </div>
    </ViewContent>
  </MyView>
</template>

<script>
export default {
  components: {
    MyView: () => import("../../views/View.vue"),
    ViewHeader: () => import("../view/ViewHeader.vue"),
    ViewContent: () => import("../view/ViewContent.vue"),
    HamburgerBtn: () => import("../home/HamburgerBtn.vue"),
    StatisticsCardTotal: () => import("./StatisticsCardTotal.vue"),
    StatisticsSubscriptions: () => import("./StatisticsSubscriptions.vue"),
    StatisticsEarnings: () => import("./StatisticsEarnings.vue"),
    StatisticsUsers: () => import("./StatisticsUsers.vue"),
  },
  data: function() {
    return {
      loading: true,
      statistics: {},
    };
  },
  methods: {
    goBack: function() {
      this.$refs.MyView.close(() => {
        this.setQuery({ statistics: undefined });
      });
    },
    getStatistics: async function() {
      this.isLoading(true);
      try {
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}statistics`);
        this.statistics = response.data?.statistics;
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
  },
  beforeMount: function() {
    this.getStatistics();
  },
};
</script>

<style lang="scss">
.statistics {
  &-content {
    width: 100%;
    max-width: $pocket_width;
    margin: 0 auto;
  }

  &-header {
    background-color: $primary-color;
    background-image: url("https://myfans1a.s3.amazonaws.com/miscellaneous/texture_1.png");
    background-size: cover;
    padding: $mpadding * 1.5;
    &-cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $mpadding;
      margin: $mpadding * 1.5 0 0 0;
    }
    &-user {
      color: #fff;
      font-size: 125%;
      .name {
        margin: $mpadding/4 0;
      }
    }
  }

  &-list {
    background-color: #fff;
    padding: $mpadding $mpadding 0 $mpadding;
    .gift-card {
      margin: 0 0 $mpadding 0;
    }
  }

  @media (min-width: $pocket_width+($mpadding*2)) {
    &-list {
      padding: 0 0 0 0;
      .gift-card {
        margin: 0 0 $mpadding 0;
      }
    }
  }

  @media (min-width: $tablet_width) {
    &-header {
      padding: $mpadding * 2;
    }
  }
}
</style>
